import _definePage_default_0 from 'C:/wamp64/www/hudour_web/resources/ts/pages/[...error].vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from 'C:/wamp64/www/hudour_web/resources/ts/pages/login.vue?definePage&vue'

export const routes = [
  {
    path: '/',
    name: 'root',
    component: () => import('C:/wamp64/www/hudour_web/resources/ts/pages/index.vue'),
    /* no children */
  },
  _mergeRouteRecord(
  {
    path: '/:error(.*)',
    name: '$error',
    component: () => import('C:/wamp64/www/hudour_web/resources/ts/pages/[...error].vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  _mergeRouteRecord(
  {
    path: '/login',
    name: 'login',
    component: () => import('C:/wamp64/www/hudour_web/resources/ts/pages/login.vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  {
    path: '/tafakkuds',
    /* internal name: 'tafakkuds' */
    /* no component */
    children: [
      {
        path: 'list',
        /* internal name: 'tafakkuds-list' */
        /* no component */
        children: [
          {
            path: '',
            name: 'tafakkuds-list',
            component: () => import('C:/wamp64/www/hudour_web/resources/ts/pages/tafakkuds/list/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'view',
        /* internal name: 'tafakkuds-view' */
        /* no component */
        children: [
          {
            path: ':id',
            name: 'tafakkuds-view-id',
            component: () => import('C:/wamp64/www/hudour_web/resources/ts/pages/tafakkuds/view/[id].vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/users',
    /* internal name: 'users' */
    /* no component */
    children: [
      {
        path: 'list',
        /* internal name: 'users-list' */
        /* no component */
        children: [
          {
            path: '',
            name: 'users-list',
            component: () => import('C:/wamp64/www/hudour_web/resources/ts/pages/users/list/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'view',
        /* internal name: 'users-view' */
        /* no component */
        children: [
          {
            path: ':id',
            name: 'users-view-id',
            component: () => import('C:/wamp64/www/hudour_web/resources/ts/pages/users/view/[id].vue'),
            /* no children */
          }
        ],
      }
    ],
  }
]
